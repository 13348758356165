<template>
  <div>
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex text-dark fw-bolder my-1 fs-3">General User Parameters
          </h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SystemSettings' }">System Settings</router-link></li>   
              <li class="breadcrumb-item text-gray-600">General User Parameters</li> 
          </ul> 
      </div> 
      <div class="d-flex align-items-center py-1">
          <router-link to="/SystemSettings">
              <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
               <span class="ms-2 fs-7"> Return back to Settings</span>
              </button>
          </router-link> 
      </div> 
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
      <RequiredField></RequiredField>
      <div class="tab-content" id="myTabContent">
        <div class="card ">
          <div class="card-header card-header-stretch">
              <h3 class="card-title">
                <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                    <li class="nav-item cursor-pointer">
                        <a class="nav-link active" data-bs-toggle="tab" @click="getData(1)">System</a>
                    </li>
                    <li class="nav-item cursor-pointer">
                        <a class="nav-link" data-bs-toggle="tab" @click="getData(2)">Controller</a>
                    </li>
                    <li class="nav-item cursor-pointer">
                        <a class="nav-link" data-bs-toggle="tab" @click="getData(3)">Company</a>
                    </li>
                </ul>
              </h3>
              <div class="card-toolbar">
              </div>
          </div>
          <div class="card-body">
            <div class="d-flex flex-column flex-md-row rounded border p-10">
              <ul class="nav nav-tabs nav-pills w-lg-25 w-md-100 w-sm-100 flex-row border-0 flex-md-column me-5 mb-3 mb-md-0 fs-6 min-w-lg-200px" role="tablist">
                  <li class="nav-item w-100 me-0 mb-md-2" role="presentation">
                      <button :class="{'active': activeTab == 1}" class="w-100 btn btn-flex px-3 btn-active-light-success" data-bs-toggle="tab" @click="setActiveTab(1)" href="#session_management" aria-selected="true" role="tab">
                          <span class="d-flex flex-column align-items-start">
                              <span class="fs-4 fw-bold">Session Management</span>
                              <small class="fs-8 text-start">Session parameters can be set here. (Automatic Logout, Lock Screen etc.)</small>
                          </span>
                      </button>
                  </li>
                  <li class="nav-item w-100 me-0 mb-md-2" role="presentation">
                      <button :class="{'active': activeTab == 2}" class="w-100 btn btn-flex px-3 btn-active-light-info" data-bs-toggle="tab" @click="setActiveTab(2)" href="#login_management" aria-selected="false" tabindex="-1" role="tab">
                          <span class="d-flex flex-column align-items-start">
                              <span class="fs-4 fw-bold">Login Management</span>
                              <small class="fs-8 text-start">Login parameters can be set here. (Login Type etc.)</small>
                          </span>
                      </button>
                  </li>
                  <li class="nav-item w-100" role="presentation">
                      <button :class="{'active': activeTab == 3}" class="w-100 btn btn-flex px-3 btn-active-light-danger" data-bs-toggle="tab" @click="setActiveTab(3)" href="#password_management" aria-selected="false" tabindex="-1" role="tab">
                          <span class="d-flex flex-column align-items-start">
                              <span class="fs-5 fw-bold">Password Management</span>
                              <small class="fs-8 text-start">Password parameters can be set here. (Password Change Period etc.)</small>
                          </span>
                      </button>
                  </li>
                  <li class="nav-item w-100" role="presentation" v-if="this.form.account_type !== 2">
                      <button :class="{'active': activeTab == 4}" class="w-100 btn btn-flex px-3 btn-active-light-warning" data-bs-toggle="tab" @click="setActiveTab(4)" href="#recaptcha_management" aria-selected="false" tabindex="-1" role="tab">
                          <span class="d-flex flex-column align-items-start">
                              <span class="fs-5 fw-bold">ReCaptcha Management</span>
                              <small class="fs-8 text-start">ReCaptcha can be activate here. (Requires <b>ReCaptcha v3</b>)</small>
                          </span>
                      </button>
                  </li>
              </ul>

              <div class="tab-content w-100" id="myTabContent">
                  <div class="tab-pane fade show active" id="kt_vtab_pane_4" role="tabpanel">
                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                      <div class="card-header">
                        <h5 class="card-title">
                          <span v-if="this.form.account_type == 1">System</span>
                          <span v-if="this.form.account_type == 2">Controller</span>
                          <span v-if="this.form.account_type == 3">Company</span>  &nbsp;
                          <span v-if="activeTab == 1">Session Management</span>
                          <span v-if="activeTab == 2">Login Management</span>
                          <span v-if="activeTab == 3">Password Management</span>
                          <span v-if="activeTab == 4">ReCaptcha Management</span>
                        </h5>
                      </div>
                      <div class="card-body">
                        <!-- Session Management: start -->
                        <div class="row" v-if="this.activeTab == 1">
                          <div class="col-lg-12">
                            <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Lock Screen Active?</label> 
                                <div class="col-lg-4 mt-3 fv-row">
                                  <input class="form-check-input mb-2" type="checkbox" id="checkbox" v-model="form.isLockScreenActive" />
                                </div> 
                              </div> 
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Automatic Logout Active?</label>  
                                <div class="col-lg-4 mt-3 fv-row">
                                  <input class="form-check-input mb-2" type="checkbox" id="checkbox" v-model="form.isAutomaticLogoutActive" />
                                </div>
                              </div>
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Lock Screen Time <small>(min)</small> </label>
                                <div class="col-lg-4 fv-row">
                                  <CurrencyInput type="text" class="form-control" name="name" :precision="0" @focus="$event.target.select()"
                                      v-model="form.lock_screen_time" :min="0" :max="30"/>
                                  <small v-if="form.lock_screen_time > 30" class="text-danger">Lock Screen Time cannot exceed 30 minutes.</small>
                                </div>
                              </div>
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Automatic Logout Time <small>(min)</small> </label>
                                <div class="col-lg-4 fv-row">
                                  <CurrencyInput type="text" class="form-control" name="name" :precision="0" @focus="$event.target.select()"
                                      v-model="form.automatic_logout_time" :min="0" :max="60"/>
                                  <small v-if="form.automatic_logout_time > 60" class="text-danger">Automatic Logout Time cannot exceed 60 minutes.</small>
                                </div>
                              </div>
                            </form>                    
                          </div>
                        </div>
                        <!-- Session Management: end -->

                        <!-- Login Management: start -->
                        <div class="row" v-if="activeTab == 2">  
                          <div class="col-lg-12">
                            <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Login Method </label>
                                <div class="col-lg-4 fv-row">
                                  <Select2 v-model="form.login_method" :options="loginMethods" :settings="{width: '100%'}" />
                                </div>
                              </div>
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Authentication Type </label>
                                <div class="col-lg-4 fv-row">
                                  <Select2 v-model="form.authentication_type" :options="authenticationTypes" :settings="{width: '100%'}" />
                                </div>
                              </div>
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Validity Type </label>
                                <div class="col-lg-4 fv-row">
                                  <Select2 v-model="form.validity_type" :options="validityTypes" :settings="{width: '100%'}" />
                                </div>
                              </div>
                              <div class="row mb-2" v-if="form.validity_type == 2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Validity Start Date
                                  <small v-if="form.validity_date_start == null" class="text-danger ms-3"> Please enter a valid date </small>
                                </label>
                                <div class="col-lg-4 fv-row">
                                  <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_start" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                </div>
                              </div>
                              <div class="row mb-2" v-if="form.validity_type == 2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Validity End Date 
                                  <small v-if="form.validity_date_end == null" class="text-danger ms-3"> Please enter a valid date </small>
                                </label>
                                <div class="col-lg-4 fv-row">
                                  <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_end" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />  
                                </div>
                              </div>
                            </form>                    
                          </div>
                        </div>
                        <!-- Login Management: end -->

                        <!-- Password Management: start -->
                        <div class="row" v-if="activeTab == 3">
                          <div class="col-lg-12">
                            <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                <div class="row mb-2">
                                  <label class="col-lg-4 col-form-label form-label fw-bold p-2">Password Change Period <small>(day)</small> </label>
                                  <div class="col-lg-4 fv-row">
                                    <CurrencyInput type="text" class="form-control" name="name" :precision="0" @focus="$event.target.select()"
                                      v-model="form.password_change_period" :min="0" :max="60"/>
                                    <small v-if="form.password_change_period > 60" class="text-danger">Password Change Period cannot exceed 60 days.</small>
                                  </div>
                                </div>
                                <div class="row mb-2">
                                  <label class="col-lg-4 col-form-label form-label fw-bold p-2">Remind Before Password Change Day <small>(day)</small> </label>
                                  <div class="col-lg-4 fv-row">
                                    <CurrencyInput type="text" class="form-control" name="name" :precision="0" @focus="$event.target.select()"
                                      v-model="form.password_change_remind_day" :min="0" :max="30"/>
                                    <small v-if="form.password_change_remind_day > 30" class="text-danger">Remind Before Password Change Day cannot exceed 30 days.</small>
                                  </div>
                                </div>
                                <div class="row mb-2">
                                  <label class="col-lg-4 col-form-label form-label fw-bold p-2">Password Confirm Type </label>
                                  <div class="col-lg-4 fv-row">
                                    <Select2 v-model="form.password_confirm_type" :options="passwordConfirmTypes" :settings="{width: '100%'}" />
                                  </div>
                                </div>
                            </form>
                          </div>
                        </div>
                        <!-- Password Management: end -->

                        <!-- ReCaptcha Management: start -->
                        <div class="row" v-if="activeTab == 4 && this.form.account_type !== 2">  
                          <div class="col-lg-12">
                            <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">                                 
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Site Key</label>
                                <div class="col-lg-4 fv-row">
                                  <input type="text" class="form-control" name="name" v-model="form.site_key" />
                                </div>
                              </div>
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">Secret Key</label>
                                <div class="col-lg-4 fv-row">
                                  <input type="text" class="form-control" name="name" v-model="form.secret_key" />
                                </div>
                              </div>
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">View Recaptcha on Login?</label> 
                                <div class="col-lg-4 mt-3 fv-row">
                                  <input class="form-check-input mb-2" type="checkbox" id="checkbox" v-model="form.isViewRecaptchaLogin" />
                                </div> 
                              </div> 
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">View Recaptcha on Forgot Password?</label>  
                                <div class="col-lg-4 mt-3 fv-row">
                                  <input class="form-check-input mb-2" type="checkbox" id="checkbox" v-model="form.isViewRecaptchaForgotPassword" />
                                </div>
                              </div>
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">View Recaptcha on Reset Password?</label>  
                                <div class="col-lg-4 mt-3 fv-row">
                                  <input class="form-check-input mb-2" type="checkbox" id="checkbox" v-model="form.isViewRecaptchaResetPassword" />
                                </div>
                              </div>
                              <div class="row mb-2">
                                <label class="col-lg-4 col-form-label form-label fw-bold p-2">View Recaptcha on Create New Company?</label>  
                                <div class="col-lg-4 mt-3 fv-row">
                                  <input class="form-check-input mb-2" type="checkbox" id="checkbox" v-model="form.isViewRecaptchaCreateNewCompany" />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <!-- ReCaptcha Management: end -->
                      </div>
                      <div class="card-footer p-3">
                        <div class="d-grid gap-2"> 
                            <button type="button" class="btn btn-lg btn-primary " @click="this.saveData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span>
                                  <strong v-if="!this.isProgressing"> Save General User Parameters </strong> 
                                    <span class="indicator-progress" v-if="this.isProgressing">
                                        Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                            </button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div> 
</template>


<script>
// Component Alias: `USC001`
import axios from 'axios' 
  
import moment from 'moment'; 
// import Swal from 'sweetalert2';

 
export default {
  name: "GeneralUserParameters",
  data() {
    return {
      activeTab: 1,
      lists: [],
      loginMethods: [{ id: 1, text: "Standard" }, { id: 2, text: "2FA" }],
      authenticationTypes: [{ id: 1, text: "SMS" }, { id: 2, text: "Google 2FA" }],
      validityTypes: [{ id: 1, text: "Always" }, { id: 2, text: "Date Range" }],
      isProgressing: false,
      accountTypes: [{ id: 1, text: "System User" }, { id: 2, text: "Controller User" }, { id: 3, text: "Company User" }], 
      passwordConfirmTypes: [{ id: 1, text: "Old Password" }, { id: 2, text: "Recovery Question" }, { id: 3, text: "Both" }], 
      form: { 
        id: null,
        account_type: 1,    
        lock_screen_time: null, 
        automatic_logout_time: null, 
        password_change_period: null, 
        site_key: null,
        secret_key: null,
        isLockScreenActive: false,
        isAutomaticLogoutActive: false,
        isViewRecaptchaLogin: false,
        isViewRecaptchaForgotPassword: false,
        isViewRecaptchaResetPassword: false,
        isViewRecaptchaCreateNewCompany: false,
        is_lock_screen_active: 0,
        is_automatic_logout_active: 0,
        password_change_remind_day: 0,
        is_view_recaptcha_login: 0,
        is_view_recaptcha_forgot_password: 0,
        is_view_recaptcha_reset_password: 0,
        is_view_recaptcha_create_new_company: 0,
        login_method : 1,
        authentication_type: 1,
        password_confirm_type: 1,
        validity_type: 1,
        validity_date_start: new Date(),
        validity_date_end: new Date(),
      }
    };
  },
  methods: {
    getData(accountType) {
      this.activeTab = 1;
      this.form.account_type = accountType;
      const params = {
        session_id: this.$getUser().session_id,
        account_type: this.form.account_type
      };
      axios.post('/api/GeneralUserParameters/Get', params, {'Content-Type': 'application/json'})
      .then((response) => {
        this.form = { 
          id: response.data.id,
          account_type: response.data.account_type == 0 ? accountType : response.data.account_type,
          lock_screen_time: response.data.lock_screen_time,
          automatic_logout_time: response.data.automatic_logout_time,
          password_change_period: response.data.password_change_period,
          password_change_remind_day: response.data.password_change_remind_day,
          is_lock_screen_active: response.data.is_lock_screen_active,
          is_automatic_logout_active: response.data.is_automatic_logout_active,
          is_view_recaptcha_login: response.data.is_view_recaptcha_login,
          is_view_recaptcha_forgot_password: response.data.is_view_recaptcha_forgot_password,
          is_view_recaptcha_reset_password: response.data.is_view_recaptcha_reset_password,
          is_view_recaptcha_create_new_company: response.data.is_view_recaptcha_create_new_company,
          site_key: response.data.site_key,
          secret_key: response.data.secret_key,
          isLockScreenActive: response.data.is_lock_screen_active == 1 ? true : false,
          isAutomaticLogoutActive: response.data.is_automatic_logout_active == 1 ? true : false,
          isViewRecaptchaLogin: response.data.is_view_recaptcha_login == 1 ? true : false,
          isViewRecaptchaForgotPassword: response.data.is_view_recaptcha_forgot_password == 1 ? true : false,
          isViewRecaptchaResetPassword: response.data.is_view_recaptcha_reset_password == 1 ? true : false,
          isViewRecaptchaCreateNewCompany: response.data.is_view_recaptcha_create_new_company == 1 ? true : false,
          login_method: response.data.login_method == 0 ? 1 : response.data.login_method,
          authentication_type: response.data.authentication_type == 0 ? 1 : response.data.authentication_type,
          validity_type: response.data.validity_type == 0 ? 1 : response.data.validity_type,
          validity_date_start:  response.data.validity_date_start,
          validity_date_end:  response.data.validity_date_end,
          password_confirm_type: response.data.password_confirm_type == 0 ? 1 : response.data.password_confirm_type,
        };
      })
      .catch(function (error) {
        console.log(error); 
      });
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;
      params.session_id = this.$getUser().session_id;
      params.is_lock_screen_active = params.isLockScreenActive == true ? 1 : 0;
      params.is_automatic_logout_active = params.isAutomaticLogoutActive == true ? 1 : 0;
      params.is_view_recaptcha_login = params.isViewRecaptchaLogin == true ? 1 : 0;
      params.is_view_recaptcha_forgot_password = params.isViewRecaptchaForgotPassword == true ? 1 : 0;
      params.is_view_recaptcha_reset_password = params.isViewRecaptchaResetPassword == true ? 1 : 0;
      params.is_view_recaptcha_create_new_company = params.isViewRecaptchaCreateNewCompany == true ? 1 : 0;
      if (params.validity_type == 2) {
        params.validity_date_start = params.validity_date_start !== null ? moment(params.validity_date_start).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.validity_date_end = params.validity_date_end !== null ? moment(params.validity_date_end).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      }
      axios.post('/api/GeneralUserParameters/Create', params, {'Content-Type': 'application/json'})
      .then((response) => { 
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.$swal("Success", "Data saved succesfully..!", 'success');
          this.form.id = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
            
      })
      .finally(function () { 
        setTimeout(() => {
          that.isProgressing = false;
        }, 500);
      });   
    },
    setActiveTab(item) {
      this.activeTab = item;
    }
  },
  created() {
    this.getData(1);    
  }
}; 
</script>