<template>
  <div>  
      <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Update Profile
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">Home</router-link></a>
                </li> 
              <li class="breadcrumb-item text-gray-600">User Profile</li>  
              <li class="breadcrumb-item text-gray-600">Update User Profile</li>
            </ul> 
        </div>
      </div>
      <div class="content flex-row-fluid mt-4" id="kt_content">
        <RequiredField></RequiredField>
        <div class="card card-xxl-stretch">
          <div class="row p-10">
              <div class="col-lg-12 mt-0"> 
                <div class="d-flex flex-row row">
                    <div class="d-flex flex-column flex-row-auto col-lg-6" :class="{'col-lg-12': userData.permission_type !== 1}"> 
                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-body">
                                <div class="row" v-if="userData.permission_type == 1">
                                  <div class="col-lg-12" v-if="userData.account_type == 2">
                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                      Controller Account
                                    </label>
                                    <Select2 v-model="form.controller_account_id" :options="controllerAccountList" :settings="{width: '100%'}"
                                      :disabled="this.authUserAccountType !== 1" />
                                  </div>
                                  <div class="col-lg-12">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Name, Surname
                                    </label>
                                    <input type="text" class="form-control" placeholder="" name="name" v-model="form.name_surname"> 
                                  </div>
                                  <div class="col-lg-12">
                                      <label class="fs-6 fw-bold form-label mt-3 required">
                                          Email
                                      </label>
                                      <input type="text" class="form-control" placeholder="" name="name" v-model="form.user_mail" autocomplete="off" disabled> 
                                  </div>
                                  <!-- <div class="col-lg-6">
                                    <div class="form-check form-switch form-check-custom mt-5">
                                      <input class="form-check-input" type="checkbox" v-model="form.isActive" id="isActive"/>
                                      <label class="form-check-label form-label mt-2" for="isActive">
                                        Is Active?
                                      </label>
                                    </div>
                                  </div> -->
                                </div>
                                <div class="row">
                                  <div class="col-lg-6">
                                  <div class="form-check form-switch form-check-custom mt-5">
                                    <input class="form-check-input" type="checkbox" v-model="form.isChangePassword" id="flexSwitchDefault"
                                    @change="onChangePassword()" />
                                    <label class="form-check-label form-label mt-2" for="flexSwitchDefault">
                                        Change Password
                                    </label>
                                  </div>
                                </div>
                                <div class="col-lg-12" v-if="form.isChangePassword">
                                  <label class="fs-6 fw-bold form-label mt-3">
                                      Old Password
                                  </label>
                                  <div class="position-relative"> 
                                  <input id="Old_Password" type="password" class="form-control" placeholder="" name="old_password" maxlength="16" v-model="form.old_password" autocomplete="off"> 
                                    <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="toggleOldPasswordVisibility()">
                                        <i v-if="isHideOld" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                        <i v-if="!isHideOld" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-lg-12" v-if="form.isChangePassword">
                                  <label class="fs-6 fw-bold form-label mt-3">
                                      New Password
                                  </label>
                                  <div class="position-relative"> 
                                  <input id="Pw_Password" type="password" class="form-control" placeholder="" name="user_password" maxlength="16" v-model="form.user_password" autocomplete="off"
                                    :class="{'border border-success': isPasswordConfirm == true}"> 
                                    <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility()">
                                        <i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                        <i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                    </span>
                                    <password-meter class="mb-3" @score="onScore" :password="form.user_password"/>
                                  </div>
                                  <div class="d-flex flex-stack mb-3 ms-1">
                                    <div class="text-gray-500 fw-semibold">
                                      Including 3 of the following:
                                    </div>
                                    <div class="text-gray-500 fw-semibold">
                                      Must Contain:
                                    </div>
                                  </div>
                                  <div class="d-flex flex-stack mb-3 ms-1">
                                    <div class="text-muted">
                                      <span class="me-3"><i class="fas" :class="isHasUppercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> ABC</span>
                                      <span class="me-3"><i class="fas" :class="isHasLowercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> abc</span>
                                      <span class="me-3"><i class="fas" :class="isHasNumber ? 'fa-check text-success' : 'fa-times text-danger'"></i> 123</span>
                                      <span class="me-3"><i class="fas" :class="isHasSpecial ? 'fa-check text-success' : 'fa-times text-danger'"></i> @#$</span>
                                    </div>
                                    <div class="text-muted" v-if="form.user_password !== null && form.user_password !== '' && form.user_password !== undefined">
                                      <span> <i class="fas" :class="form.user_password.length > 8 ? 'fa-check text-success' : 'fa-times text-danger'"></i> 8-16 Chars </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12" v-if="form.isChangePassword">
                                  <label class="fs-6 fw-bold form-label mt-3">
                                      Confirm Password
                                  </label>
                                  <input type="password" class="form-control" placeholder="" name="confirm_password" maxlength="16" v-model="form.confirm_password" autocomplete="off"
                                    :class="{'border border-success': isPasswordConfirm == true,
                                      'border border-danger': isPasswordConfirm == false}" @paste.prevent> 
                                  <small class="text-danger" v-if="isPasswordConfirm == false">
                                    The password and its confirm are not the same
                                  </small>
                                </div>
                                </div> 
                            </div>
                        </div> 
                    </div>
                    <div class="d-flex flex-column flex-row-auto col-lg-6" v-if="userData.permission_type == 1"> 
                      <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-lg-12">
                                  <label class="fs-6 fw-bold form-label mt-3">
                                      Phone
                                  </label>
                                  <div class="input-group">
                                    <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                    <input type="text" class="form-control" name="name" v-model="form.phone"> 
                                  </div>
                              </div>
                            <div class="col-lg-6">
                              <label class="fs-6 fw-bold form-label mt-3">
                                Gender
                              </label>
                              <Select2 v-model="form.gender" :options="genderList" :settings="{ width: '100%' }"/>
                            </div>
                            <div class="col-lg-6">
                              <label class="fs-6 fw-bold form-label mt-3">
                                Date of Birth
                              </label>
                              <Datepicker name="date_of_birth" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.date_of_birth" 
                              format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" :maxDate="new Date()" />
                            </div>
                            <div class="col-lg-12">
                              <label class="fs-6 fw-bold form-label mt-3">
                                Permission Type
                              </label>
                              <Select2 v-model="form.permission_type" :options="permissionTypes" :settings="{width: '100%'}" />
                            </div>
                          </div> 
                        </div>
                      </div> 
                    </div>
                </div>
              </div>
              <div class="col-lg-12" v-if="userData.permission_type == 1">
                  <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                      <div class="card-body pb-0">
                          <form id="kt_ecommerce_settings_general_form"
                              class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                              <div class="fv-row mb-7 row">
                                  <div class="col-lg-6">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Login Method
                                    </label> 
                                    <Select2 v-model="form.login_method" :options="loginMethods" :settings="{width: '100%'}" />
                                  </div>
                                  <div class="col-lg-6" v-if="form.login_method == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Authentication Type
                                    </label> 
                                    <Select2 v-model="form.authentication_type" :options="authenticationTypes" :settings="{width: '100%'}" />
                                  </div>
                                  <div class="col-lg-6">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Validity Type
                                    </label> 
                                    <Select2 v-model="form.validity_type" :options="validityTypes" :settings="{width: '100%'}" />
                                  </div>
                                  <div class="col-lg-6" v-if="form.validity_type == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Validity Start Date
                                      <small v-if="form.validity_date_start == null" class="text-danger ms-3"> Please enter a valid date </small>
                                    </label> 
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_start" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                  </div>
                                  <div class="col-lg-6" v-if="form.validity_type == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                      Validity End Date
                                      <small v-if="form.validity_date_end == null" class="text-danger ms-3"> Please enter a valid date </small>
                                    </label> 
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_end" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />  
                                  </div>
                                  <div class="col-lg-12">
                                      <label class="fs-6 fw-bold form-label mt-3">
                                          Description
                                      </label>
                                      <textarea type="text" rows="2" class="form-control" placeholder="" name="name" v-model="form.user_description" /> 
                                  </div>
                                  <div class="col-lg-12" v-if="form.login_method == 2 && form.authentication_type == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">Google Auth Setup</label> 
                                    <br> 
                                      <div class="alert alert-primary d-flex align-items-center p-5"> 
                                          <div class="d-flex flex-column"> 
                                              <h4 class="mb-1 text-dark"></h4> 
                                              <span>
                                                <p>
                                                    Download Google's two-factor authenticator: <br>
                                                    Google Authenticator for
                                                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en" target="_blank">Android</a> and
                                                    <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank">iOS</a>.
                                                </p>
                                                <p>Click Generate QR Code button below. Scan the QR Code on app. </p>
                                                <p>
                                                    Once you have scanned the QR code, your two factor authentication app will register you to two-factor authentication system. 
                                                </p>
                                              </span>
                                          </div>
                                      </div>
                                    <button type="button" class="btn btn-primary btn-sm" @click="onGenerateQRCode()">
                                        Generate QR Code
                                    </button>
                                    <div v-if="qrImage" class="mt-4">
                                        <img v-bind:src="qrImage" />
                                    </div> 
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
              <div class="col-lg-12 mt-0"> 
                <div class="d-grid gap-2"> 
                    <button type="button" class="btn btn-lg btn-primary " @click="this.saveData()" data-kt-indicator="on" :disabled="form.old_password == '' || isPasswordConfirm == false || (form.isChangePassword && passwordScore < 3)">
                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                        </svg>
                        </span>
                          <strong v-if="!this.isProgressing"> Update Profile </strong> 
                            <span class="indicator-progress" v-if="this.isProgressing">
                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                    </button>
                </div> 
              </div>
          </div> 
        </div>
      </div> 
  </div> 
</template>
  
  <script>
  // Component Alias: `UP001`
  import axios from 'axios'; 
  import moment from 'moment';
    
  // import Swal from 'sweetalert2';
  
  import PasswordMeter from "../../../scripts/passwordMeter.js";
   
  export default {
    name: "UserAdminProfile",
    components: {
      PasswordMeter
    },
    data() {
      return {
        passwordScore: 0,
        isOpenActionsDropdown: false,
        lists: [],
        isProgressing: false,
        accountTypes: [{ id: 1, text: "System User" }, { id: 2, text: "Controller User" }, { id: 3, text: "Company User" }],
        permissionTypes: [{ id: 1, text: "Admin" }, { id: 2, text: "Client" }],
        loginMethods: [{ id: 1, text: "Standard" }, { id: 2, text: "2FA" }],
        authenticationTypes: [{ id: 1, text: "SMS" }, { id: 2, text: "Google 2FA" }],
        validityTypes: [{ id: 1, text: "Always" }, { id: 2, text: "Date Range" }],
        controllerAccountList: [],
        userData: this.$getUser(),
        form: { 
          id: null,
          account_type: 1,
          permission_type: 1,
          company_account_id: null,
          controller_account_id: null,
          system_account_id: null,
          user_mail: null,
          user_password: '',
          old_password: '',
          name_surname: null,
          phone: null,
          user_description: null,
          login_method : 1,
          authentication_type: 1,
          validity_type: 1,
          validity_date_start: new Date(),
          validity_date_end: new Date(),
          password_validity: -1,
          is_active: 1,
          isActive: true,
          isChangePassword: false,
          confirm_password: '',
          phone_country_code: null,
          gender: '-1',
          date_of_birth: null
        },
        isHide: true,
        isHideOld:true,
        authUserAccountType: null,
        qrImage: null,
        authCode: null,
        phoneMaskList: [],
        genderList: [
          {id: '-1', text: 'Select an option'},
          {id: 'M', text: 'Male'},
          {id: 'F', text: 'Female'},
          {id: 'O', text: 'Other'},
        ],
      };
    },
    computed: {
      isPasswordConfirm(){
        if(this.form.isChangePassword) {
          if(this.form.user_password !== null && this.form.user_password !== '') {
            if(this.form.user_password == this.form.confirm_password) return true;
            else return false;
          }
          else return null;
        }
        else { return null;}
      },
      isHasNumber() {
        return /(.*[0-9].*)/g.test(this.form.user_password);
      },
      isHasLowercase() {
        return /(.*[a-z].*)/g.test(this.form.user_password);
      },
      isHasUppercase() {
        return /(.*[A-Z].*)/g.test(this.form.user_password);
      },
      isHasSpecial() {
        return /[^A-Za-z0-9]/g.test(this.form.user_password);
      }
    },
    methods: {
      getData() {
        this.lists = []; 
        const parameters = {
          id: this.$getUser().id,
          session_id: this.$getUser().session_id
        };  
        
        const requestOne =  axios.post('/api/Users/GetUser', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Lists/GetPhoneMasks', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {            
          this.form = { 
            id: responses[0].data.id,
            account_type: responses[0].data.account_type,
            permission_type: responses[0].data.permission_type,
            company_account_id: this.$unlock(responses[0].data.company_account_id),
            controller_account_id: this.$unlock(responses[0].data.controller_account_id),
            system_account_id: this.$unlock(responses[0].data.system_account_id),
            user_mail: this.$unlock(responses[0].data.user_mail),
            name_surname: this.$unlock(responses[0].data.name_surname),
            phone: this.$unlock(responses[0].data.phone),
            phone_country_code: this.$unlock(responses[0].data.phone_country_code),
            user_password: '',
            confirm_password: '',
            isChangePassword: false,
            user_description: responses[0].data.user_description,
            login_method : responses[0].data.login_method,
            authentication_type: responses[0].data.authentication_type,
            validity_type: responses[0].data.validity_type,
            validity_date_start: responses[0].data.validity_date_start,
            validity_date_end: responses[0].data.validity_date_end,
            password_validity: responses[0].data.password_validity,
            is_active: responses[0].data.is_active, 
            isActive: responses[0].data.is_active == 1 ? true : false,
            user_assignment_id: responses[0].data.user_assignment_id == '' ? null : responses[0].data.user_assignment_id,
            is_owner: responses[0].data.is_owner,
            isOwner: responses[0].data.is_owner == 1 ? true : false,
            is_chatbi_user: responses[0].data.is_chatbi_user,
            isChatbiUser: responses[0].data.is_chatbi_user == 1 ? true : false,
            gender: responses[0].data.gender == '' ? null : responses[0].data.gender,
            date_of_birth: responses[0].data.date_of_birth,
          };
          this.phoneMaskList = responses[1].data;

          if(this.userData.account_type == 2) {
            this.controllerAccountList.push({
              id: this.userData.controller_account_id,
              text: this.userData.controller_account_name
            })
          }
        }))
        .catch(errors => { 
            console.error(errors); 
        });
      },
      togglePasswordVisibility() {
        this.isHide = !this.isHide;
        var x = document.getElementById("Pw_Password");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      },
      toggleOldPasswordVisibility() {
        this.isHideOld = !this.isHideOld;
        var x = document.getElementById("Old_Password");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      },
      saveData(){
        if(this.form.isChangePassword == true) {
          if(this.form.user_password == null || this.form.user_password == '') return;
          if(this.form.user_password !== this.form.confirm_password) {
            this.$swal("Failed", "The passwords you entered do not match..!", 'error');
            return;
          }
          else if(this.passwordScore < 3) {
            this.$swal("Failed", "Please specify a strong password..!", 'error');
            return;
          }
        }
        this.isProgressing = true;
        const params = this.form; 
        const that = this; 
        params.licence_id = this.$getUser().system_account_id;
        params.controller_account_id = this.$getUser().controller_account_id; 
        params.system_account_id = this.$getUser().system_account_id;
        params.is_active = params.isActive == true ? 1 : 0;
        params.session_id = this.$getUser().session_id;
        params.company_account_id = null;
        if (params.gender == '-1') {params.gender = null;}
        params.date_of_birth = params.date_of_birth !== null ? moment(params.date_of_birth).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        axios.post('/api/Users/UpdateUser', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');     
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
      },
      onGenerateQRCode(){
        const parameters = {
          user_id: this.$getUser().id,
          user_mail: this.form.user_mail
        }; 
        axios.post('/api/Users/GetAuthenticationCode', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
              this.qrImage = response.data;
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
      },
      onScore(pwScore) {
        this.passwordScore = pwScore.score;
      },
      onChangePassword() {
        this.form.user_password = '';
        this.form.confirm_password = '';
        this.form.old_password = '';
      },
    },
    mounted() { 
      this.getData();
    }
  }; 
  </script>